import { Box } from "@storyofams/react-ui";
import { withStory, WithStoryProps } from "@storyofams/storyblok-toolkit";
import { GetStaticProps, NextPage } from "next";

import { Seo, Heading } from "../components";
import { Search } from "../components/search";
import {
  enhancedStaticProps,
  loadCollectionSections,
  withAuth,
  withLayout,
} from "../lib";

interface SearchPageProps extends WithStoryProps {}

const SearchPage: NextPage<SearchPageProps> = ({ story }) => {
  return (
    <>
      <Seo story={story} description="Zoeken" noIndex />

      <Box width="100%" textAlign="center">
        <Heading id="title" variant="h3" as="h1" className="hidden">
          {story?.content?.title}
        </Heading>
      </Box>

      <Search body={story?.content?.body} />
    </>
  );
};

export default withAuth(withLayout(withStory(SearchPage)));

export const getStaticProps: GetStaticProps<SearchPageProps> =
  enhancedStaticProps(async ({ sdk, locale }) => {
    let story = null;
    let notFound = false;

    try {
      story = (
        await sdk.search({
          slug: `search`,
        })
      ).SearchItem;
    } catch (err) {
      notFound = true;
    }

    await loadCollectionSections(story, locale);

    return {
      props: {
        story: story || null,
      },
      notFound,
      revalidate: process.env.HOST === "dev.naturalheroes.nl" ? 1 : 3600,
    };
  });
